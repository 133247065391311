import * as React from "react";
import LanguageToggle from "./LanguageToggle";

const Navbar = () => {
    return (
        <div className="navbar">
            <button>Book Now</button>
            <nav>
            <ul>
                <li>About</li>
                <li>Amenities</li>
                <li>Contact</li>
            </ul>
            </nav>
            <LanguageToggle />
        </div>
    )
}

export default Navbar